import AOS from "aos";
import { Modal } from "bootstrap";

document.addEventListener("DOMContentLoaded", function () {
	/*
	-------------------------------
	Animaciones scroll
	-------------------------------
	*/
	AOS.init();

	var scrollRef = 0;
	window.addEventListener("scroll", function () {
		// increase value up to 10, then refresh AOS
		if (scrollRef <= 10) {
			scrollRef++;
		} else {
			AOS.refresh();
		}
	});

	/*
	-------------------------------
	Mobile menu
	-------------------------------
	*/
	const mnToggleBtn = document.querySelector(".side-toggle button");
	const mobileNavCont = document.querySelector(".mobile-nav-cont");

	mnToggleBtn.addEventListener("click", () => {
		mobileNavCont.classList.toggle("is-active");
	});

	/*
	-------------------------------
	Welcome modal
	-------------------------------
	*/
	const modalElement = document.getElementById("welcomeModal");
	if (modalElement) {
		const modal = new Modal(modalElement, {});
		setTimeout(() => {
			modal.show();
		}, 1000);
	}
});
